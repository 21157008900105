























































const R = require("ramda");
import Vue from "vue";
import axios from "axios";

import * as L from "leaflet";
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LIcon,
  LTooltip,
} from "vue2-leaflet";
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";

export default Vue.extend<any, any, any, any>({
  name: "ResultMap",
  data: function () {
    return {
      mapVisible: false,
      center: L.latLng(47.41322, -1.219482),
      url:
        "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
      attribution:
        "Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community",
      maxZoom: 22,
      marker: L.latLng(47.41322, -1.219482),
      options: { dragging: !L.Browser.mobile, tap: !L.Browser.mobile },
      clusterOptions: {
        spiderfyDistanceMultiplier: 2,
        maxClusterRadius: 30,
      },
      icon: L.icon({
        iconUrl:
          "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png",
        shadowUrl:
          "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
        //   iconSize: [32, 37],
        //   iconAnchor: [16, 37]
      }),
    };
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    "v-marker-cluster": Vue2LeafletMarkerCluster,
  },
  watch: {
    mapVisible: function (new_v, old_v) {
      document.getElementById("huetten-mainlist").style.display = new_v
        ? "none"
        : "block";

      if (new_v == true) {
        this.$nextTick(() => {
          let bounds = this.$refs.clusterRef.mapObject.getBounds();
          console.log("#log 1278", bounds);
          this.$refs.myMap.mapObject.fitBounds(bounds, { maxZoom: 8 });
        });
      }
    },
    items: function (new_v, old_v) {
      // var latLngBounds = a.layer.getBounds();
      console.log("#log", "repaint map");
      // this.$nextTick(() => {
      //   let bounds = this.$refs.clusterRef.mapObject.getBounds();
      //   this.$refs.myMap.mapObject.fitBounds(bounds, { maxZoom: 5 });
      // });
      // this.paintMarkers(new_v);
    },
  },
  methods: {
    markerReady: function (item, marker) {
      var self = this;
      if (marker) {
        marker.bindPopup("Loading...", { maxWidth: 600 });
        marker.on("click", function (e) {
          var popup = e.target.getPopup();

          self
            .getHtmlForHuette(item.id)
            .then((res) => {
              console.log("#log 9376", res);
              popup.setContent(res.data);
              popup.update();

              let huettenSwiper = new window.Swiper(`#huettenimgs-${item.id}`, {
                loop: true,
                pagination: { el: ".swiper-pagination", clickable: true },
                on: {
                  tap: function (e, swiper) {
                    huettenSwiper.slideNext();
                  },
                },
              });
            })
            .catch((res) => {
              console.error(res);
              // handleErrorsFromResponse(this, res);
            });
        });
      }
    },
    hasLocation: function (item) {
      if (item.lat !== null && item.lon != null) {
        return true;
      } else {
        return false;
      }
    },
    getIconForIdx: function (idx) {
      return idx == 0 ? this.icon : this.subicon;
    },
    getHtmlForHuette: function (huette_id) {
      return new Promise((resolve, reject) => {
        axios
          .get(this.$store.getters.extconf.apiurl + "?id=" + huette_id)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            console.error(error);
            reject(error.response);
          });
      });
    },
  },
  computed: {
    zoomInt: function () {
      return 11;
    },
    items: function () {
      return R.pipe(
        R.map(([id, lat, lon, title]) => {
          return { id, lat, lon, title };
        })
        // R.join(",")
      )(this.$store.getters["extconf"].huetten);
    },
  },
  mounted: function () {},
});

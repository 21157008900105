















































const R = require("ramda");
import Vue from "vue";

import * as L from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup, LIcon } from "vue2-leaflet";
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";

export default Vue.extend<any, any, any, any>({
  name: "DetailMap",
  props: {
    lat: { type: String, required: true },
    lon: { type: String, required: true },
    name: { type: String, required: true },
    zoom: { type: String, default: "7" },
  },

  data: function () {
    return {
      center: L.latLng(this.lat, this.lon),
      url:
        "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
      attribution:
        "Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community",
      maxZoom: 22,
      marker: L.latLng(47.41322, -1.219482),
      options: { dragging: !L.Browser.mobile, tap: !L.Browser.mobile },
      clusterOptions: {
        spiderfyDistanceMultiplier: 2,
        maxClusterRadius: 30,
      },
      icon: L.icon({
        iconUrl:
          "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
        shadowUrl:
          "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
        //   iconSize: [32, 37],
        //   iconAnchor: [16, 37]
      }),
      subicon: L.icon({
        iconUrl:
          "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png",
        shadowUrl:
          "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
        //   iconSize: [32, 37],
        //   iconAnchor: [16, 37]
      }),
    };
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    "v-marker-cluster": Vue2LeafletMarkerCluster,
  },
  watch: {
    items: function (new_v, old_v) {
      // var latLngBounds = a.layer.getBounds();
      // console.log("#log", "repaint");
      // this.$nextTick(() => {
      //   let bounds = this.$refs.clusterRef.mapObject.getBounds();
      //   this.$refs.myMap.mapObject.fitBounds(bounds, { maxZoom: 5 });
      // });
      // this.paintMarkers(new_v);
    },
  },
  methods: {
    hasLocation: function (item) {
      if (item.lat !== null && item.lon != null) {
        return true;
      } else {
        return false;
      }
    },
    getIconForIdx: function (idx) {
      return idx == 0 ? this.icon : this.subicon;
    },
  },
  computed: {
    zoomInt: function () {
      return parseInt(this.zoom, 10);
    },
    items: function () {
      return [
        { lat: this.lat, lon: this.lon, id: 1, title: this.name },
        ...this.$store.getters["extconf"].map_items,
      ];
    },
  },
});

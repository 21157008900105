//https://medium.com/carwow-product-engineering/building-a-simple-tooltip-component-that-never-goes-off-screen-c7039dcab5f9

class Tooltip extends HTMLElement {
    connectedCallback() {
        this.setup();
    }

    handleDropdownPosition() {
        console.log('#log 8624', 'place tt1');
        const screenPadding = 16;

        const placeholderRect = this.placeholder.getBoundingClientRect();
        const dropdownRect = this.dropdown.getBoundingClientRect();

        const dropdownRightX = dropdownRect.x + dropdownRect.width;
        const placeholderRightX = placeholderRect.x + placeholderRect.width;

        if (dropdownRect.x < 0) {
            this.dropdown.style.left = '0';
            this.dropdown.style.right = 'auto';
            this.dropdown.style.transform = `translateX(${-placeholderRect.x + screenPadding}px)`;
        } else if (dropdownRightX > window.outerWidth) {
            this.dropdown.style.left = 'auto';
            this.dropdown.style.right = '0';
            this.dropdown.style.transform = `translateX(${(window.outerWidth - placeholderRightX) - screenPadding}px)`;
        }
    }

    toggle() {
        console.log('#log 1985', 'toggle');
        if (this.classList.contains('mw-tooltip--open')) {
            this.close();
        } else {
            this.open();
        }
    }

    open() {
        console.log('#log 3964', 'open ak', this.classList);
        this.classList.add('mw-tooltip--open');
        console.log('#log 3964', 'open b', this.classList);
        // this.handleDropdownPosition();
    }

    close() {
        this.classList.remove('mw-tooltip--open');
    }

    setup() {
        this.placeholder = this.querySelector('[data-tooltip-placeholder]');
        this.dropdown = this.querySelector('[data-tooltip-dropdown]');
        this.placeholder.addEventListener('mouseover', () => this.handleDropdownPosition());
        this.placeholder.addEventListener('touchstart', () => this.toggle());
    }
}

var dismissAllTooltips = function (event) {
    if (typeof event.target.closest !== 'function') return;
    const currentTooltip = event.target.closest('mw-tooltip');

    document.querySelectorAll('.mw-tooltip--open').forEach(tooltip => {
        if (tooltip === currentTooltip) return;

        tooltip.classList.remove('mw-tooltip--open');
    });
}


export {
    Tooltip,
    dismissAllTooltips
}

import Vue from "vue";
import VueRouter from "vue-router";
import DetailMap from "../components/DetailMap.vue";
import ResultMap from "../components/ResultMap.vue";
import SearchForm from "../components/SearchForm.vue";
const make_router = function(widgetProps: object) {
  return new VueRouter({
    linkActiveClass: "is-active",
    mode: "abstract",
    routes: [
      {
        path: "/detail_map",
        component: DetailMap,
        name: "huetten_fe-detail_map",
        props: widgetProps,
      },
      {
        path: "/result_map",
        component: ResultMap,
        name: "huetten_fe-result_map",
        props: widgetProps,
      },
      {
        path: "/search_form",
        component: SearchForm,
        name: "huetten_fe-search_form",
        props: widgetProps,
      },
    ],
  });
};

export default make_router;

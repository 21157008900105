














































































































const R = require("ramda");
import Vue from "vue";
import { array_get } from "../utils";

export default Vue.extend<any, any, any, any>({
  name: "SearchForm",
  props: {},

  data: function () {
    return {
      query: { ...this.$store.getters.extconf.query },
      fdata: {
        kw: "",
        regions: [],
        allgemein: [],
        erreichbarkeit: [],
        besonderheiten: [],
        payment: [],
      },
    };
  },
  components: {},
  watch: {},
  methods: {
    toggleKey: function (bucket, item) {
      if (this.hasKey(bucket, item)) {
        this.$delete(bucket, bucket.indexOf(item));
      } else {
        bucket.push(item);
      }
    },
    hasKey: function (bucket, item) {
      return R.contains(item, bucket);
    },
    encodeQueryData: function (data) {
      const ret = Array();
      for (let d in data) {
        ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
      }
      return ret.join("&");
    },
    getUrlParamsFromFdata: function (fdata) {
      let urlparams = {};
      let kw = fdata.kw.trim();
      if (kw.length > 1) {
        urlparams["kw"] = kw;
      }
      if (this.query.hot == "1") {
        urlparams["hot"] = "1";
      }
      if (this.fdata.regions.length > 0) {
        urlparams["regions"] = this.fdata.regions.join("-");
      }
      if (this.fdata.allgemein.length > 0) {
        urlparams["allgemein"] = this.fdata.allgemein.join("-");
      }
      if (this.fdata.erreichbarkeit.length > 0) {
        urlparams["erreichbarkeit"] = this.fdata.erreichbarkeit.join("-");
      }
      if (this.fdata.besonderheiten.length > 0) {
        urlparams["besonderheiten"] = this.fdata.besonderheiten.join("-");
      }
      if (this.fdata.payment.length > 0) {
        urlparams["payment"] = this.fdata.payment.join("-");
      }
      return urlparams;
    },
    doSubmit: function (e) {
      e.preventDefault();
      let urlParams = this.getUrlParamsFromFdata(this.fdata);
      let newUrl =
        `${this.settings.baseurl}?` + this.encodeQueryData(urlParams);
      window.location.href = newUrl;
    },
    updateFdataFromQuery: function () {
      this.fdata.kw = array_get(this.query, "kw", "");
      this.fdata.hot = array_get(this.query, "hot", "");
      this.fdata.regions = array_get(this.query, "regions", "")
        .split("-")
        .filter((o) => o);
      this.fdata.allgemein = array_get(this.query, "allgemein", "")
        .split("-")
        .filter((o) => o);
      this.fdata.erreichbarkeit = array_get(this.query, "erreichbarkeit", "")
        .split("-")
        .filter((o) => o);
      this.fdata.besonderheiten = array_get(this.query, "besonderheiten", "")
        .split("-")
        .filter((o) => o);
      this.fdata.payment = array_get(this.query, "payment", "")
        .split("-")
        .filter((o) => o);
    },
  },
  computed: {
    settings: function () {
      return this.$store.getters.extconf;
    },
    debugUrlParams: function () {
      return this.getUrlParamsFromFdata(this.fdata);
    },
  },
  created: function () {
    console.log("#log 4676");
    this.updateFdataFromQuery();
  },
});













import Vue from "vue";

export default Vue.extend({
  props: {
    data: { type: Object, required: true }
  }
});
